<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar
        color="greyBase"
        dark
        :extended="!$vuetify.breakpoint.mobile"
        flat
        extension-height="55"
      >
        <v-toolbar-title>
          <b class="mr-3">Order Settings</b>
        </v-toolbar-title>
      </v-toolbar>

      <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
          'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
        }"
      >
        <v-card-text
          :style="{
            height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
            'overflow-y': 'auto',
          }"
        >
          <!-- <v-container> -->
            <v-row align="start" justify="center">
              <v-col cols="12" sm="3" class="my-0">
                <v-card
                  style="
                    border-radius: 20px;
                    background-color: var(--v-toolbar-lighten1) !important;
                  "
                >
                  <v-card-title>
                    <v-icon class="mr-2" color="grey">category</v-icon>
                    Traded Products <v-spacer></v-spacer>
                    <v-btn icon color="primary" @click="productModal = true"
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    >
                  </v-card-title>
                  <v-card-text style="height: 77vh; overflow-y: auto">
                    <v-list dense>
                      <v-list-item
                        v-for="product in tradedProducts"
                        :key="product.id"
                        @click="selectProduct(product)"
                        :style="{
                          'border-left':
                            selectedProduct && selectedProduct.id == product.id
                              ? '3px solid var(--v-secondary-base)'
                              : '3px solid transparent',
                        }"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{
                            product.product.name
                          }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ product.product.type }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            v-if="product.parentProduct"
                            style="font-size: 12px"
                          >
                            Parent: {{ product.parentProduct.name }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            icon
                            color="red"
                            @click="removeTradedProductPrompt(product)"
                          >
                            <v-icon>cancel</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="tradedProducts.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="font-size: 12px; color: grey"
                            >No traded products found</span
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-card flat style="background-color: transparent !important"
                >
                  <v-card-title>
                    <v-icon color="grey" class="mr-2">settings</v-icon>
                    Product Descriptions
                  </v-card-title>
                  <v-card-subtitle v-if="selectedProduct.product">
                    Product: {{ selectedProduct.product.name }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-list dense  v-if="selectedProduct && selectedProduct.product">
                      <v-autocomplete
                          label="Base Unit"
                          v-model="selectedProduct.baseUnit"
                          :items="productQuantityTypes"
                          @change="updateProductSettings"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                        ></v-autocomplete>
                      <v-list-item>
                        <v-list-item-action class="mx-0 px-0 mr-1">
                          <v-icon color="grey" small>widgets</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Palletised</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-switch
                            v-model="selectedProduct.palletised"
                            @change="updateProductSettings"
                            hide-details
                            color="blue"
                          ></v-switch>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action class="mx-0 px-0 mr-1">
                          <v-icon color="grey" small>widgets</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Containerised</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-switch
                            v-model="selectedProduct.containerised"
                            @change="updateProductSettings"
                            hide-details
                            color="blue"
                          ></v-switch>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="selectedProduct.containerised && selectedProduct.palletised">
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        >Pallets per Container</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <el-input-number
                        :min="0"
                        @change="updateProductSettings"
                        :value="selectedProduct.palletPerContainer"
                        size="mini"
                        v-model="selectedProduct.palletPerContainer"
                      ></el-input-number>
                    </v-list-item-action>
                  </v-list-item>
                
                  <v-list-item v-if="!selectedProduct.containerised && selectedProduct.palletised">
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ formatBaseUnit(selectedProduct.baseUnit) }}(s) per Pallet</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <el-input-number
                        :min="0"
                        @change="updateProductSettings"
                        :value="selectedProduct.unitPerPallet"
                        size="mini"
                        v-model="selectedProduct.unitPerPallet"
                      ></el-input-number>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="selectedProduct.containerised">
                    <v-list-item-action class="mx-0 px-0 mr-1">
                      <v-icon color="grey" small>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> {{ formatBaseUnit(selectedProduct.baseUnit) }}(s) per Container</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <!-- <v-text-field
                            style="width: 100px"
                            dense
                            outlined
                            rounded
                            v-model="selectedProduct.unitPerContainer"
                            @change="updateProductSettings"
                            hide-details
                            type="number"
                          ></v-text-field> -->
                      <el-input-number
                        :min="0"
                        :value="selectedProduct.unitPerContainer"
                        size="mini"
                        @change="updateProductSettings"
                        v-model="selectedProduct.unitPerContainer"
                      ></el-input-number>
                    </v-list-item-action>
                  </v-list-item>                     
                    </v-list>
                    <v-list-item v-if="!selectedProduct.product">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 14px; color: grey">
                          Please first select/add a Traded Product.
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
                  </v-card-text>
                </v-card>
              </v-col>
             
             
              <v-col cols="12" sm="4" class="my-0">
                <v-card
                  flat
                  style="
                    border-radius: 20px;
                    background-color: var(--v-toolbar-lighten1) !important;
                  "
                >
                  <v-card-title>
                    <v-icon color="grey" class="mr-2">settings</v-icon>
                    Product Settings
                  </v-card-title>
                  <v-card-subtitle v-if="selectedProduct.product">
                    Product: {{ selectedProduct.product.name }}
                  </v-card-subtitle>
                  <v-card-text  v-if="selectedProduct && selectedProduct.product" style="height: 75vh; overflow-y: auto">
                    <v-list
                      dense
                      v-for="(key, index) in types"
                      :key="index"
                      subheader
                    >
                      <v-subheader style="font-size: 14px; font-weight: bold"
                        >{{ key.key }} <v-spacer></v-spacer>
                        <v-btn icon color="primary" @click="addItem(key)"
                          ><v-icon>add_circle_outline</v-icon></v-btn
                        ></v-subheader
                      >
                      <v-divider></v-divider>
                      <v-chip-group>
                        <v-chip
                          class="mx-1"
                          small
                          v-for="data in filteredValues(key.value)"
                          @click="editItem(data, key)"
                          :key="data.id"
                        >
                          {{ data.value }}
                        </v-chip>
                      </v-chip-group>
                      <v-list-item v-if="filteredValues(key.value).length == 0">
                        <v-list-item-content class="text-center">
                          <span style="font-size: 12px; color: grey"
                            >No Data</span
                          >
                        </v-list-item-content>
                      </v-list-item>
                      
                    </v-list>
                  <v-list dense subheader>
                    <v-divider></v-divider>
                      <v-subheader style="font-size: 16px">Traded Variations <v-spacer></v-spacer>
                      <v-btn icon color="primary" @click="addTradedVariation()">
                        <v-icon>add_circle_outline</v-icon>
                      </v-btn>
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-chip-group>
                        <v-chip
                          class="mx-1"
                          small
                          v-for="data in filterTradedVariations"
                          @click="editTradedVariation(data)"
                          @contextmenu="clickTradedVariation($event, data)"
                          :key="data.id"
                        >
                        {{ data.packCode }} - {{ data.countSize }} 
                        </v-chip>
                      </v-chip-group>
                      <!-- <v-virtual-scroll v-if="filterTradedVariations.length >0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterTradedVariations"
                    height="350"
                    item-height="40"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item @click="editTradedVariation(item)">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.packCode }} <v-chip style="border:none" outlined small v-if="item.countSize" class="mx-1">
                             Count: {{ item.countSize }}
                          </v-chip>
                          <v-chip style="border:none" outlined small v-if="item.inventoryCode" class="mx-1">
                             Inventory: {{ item.inventoryCode }}
                          </v-chip>
                        </v-list-item-title>
                      </v-list-item-content>
                      </v-list-item>
</template>
                      </v-virtual-scroll> -->
                      <!-- <v-list-item v-for="variation in filterTradedVariations" :key="variation.id">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ variation.packCode }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip style="border:none" outlined small v-if="variation.countSize" class="mx-1">
                           Count: {{ variation.countSize }}
                          </v-chip>
                          <v-chip style="border:none" outlined small v-if="variation.inventoryCode" class="mx-1">
                           Inventory: {{ variation.inventoryCode }}
                          </v-chip>
                        </v-list-item-subtitle>  
                      </v-list-item-content>
                      </v-list-item> -->
                      <v-list-item v-if="filterTradedVariations.length == 0">
                        <v-list-item-content class="text-center">
                          <span style="font-size: 12px; color: grey"
                            >No Data</span
                          >
                        </v-list-item-content>
                      </v-list-item>
                  </v-list>
                  </v-card-text>
                  <v-card-text v-else>
                    <v-list-item>
                      <v-list-item-content class="text-center">
                        <span style="font-size: 14px; color: grey">
                          Please first select/add a Traded Product.
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4">
                <v-card
                :loading="loadingProductionAlias"
                  style="
                    border-radius: 20px;
                    background-color: var(--v-toolbar-lighten1) !important;
                  "
                >
              <v-divider></v-divider>
                  <v-card-title>
                    <v-icon class="mr-2" color="grey">category</v-icon>
                    Production Aliases <v-spacer></v-spacer>
                    <v-btn icon color="primary" @click="productionAliasModal = true"
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    >
                  </v-card-title>
                  <v-card-text style="height: 77vh; overflow-y: auto">
                    <v-list dense>
                      <v-list-item v-if="productionAliases.length == 0">
                      <v-list-item-content class="text-center">
                        <span style="font-size: 12px; color: grey">No Production Aliases</span>
                      </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-for="item in productionAliases"
                        :key="item.id"
                        @click="editProductionAlias(item)"
                        >
                      <v-list-item-action>
                        <v-avatar size="32" :color="item.logo?'white':'secondary'">
                        <v-img :src="item.logo" v-if="item.logo" contain></v-img>
                      <h3 v-else>
                        {{ item.name.charAt(0) }}
                      </h3>
                      </v-avatar>
                      </v-list-item-action>
                      <v-list-item-title>
                        <span v-if="item.alias">{{ item.alias }}</span>
                        <span v-else>{{ item.name }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip x-small v-for="alias in item.aliases" :key="alias.id" label>
                        {{ alias.code }}
                        </v-chip>
                      </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                    </v-card-text>
                    </v-card>
              </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog
      v-model="modal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 14px"
            >Order Setting: {{ item.friendlyName }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="secondary"
            :loading="saving"
            @click="saveOrderSetting"
          >
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn
            icon
            color="red"
            v-if="item.id"
            :loading="deleting"
            @click="deleteOrderSetting"
          >
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn text @click="modal = false"> x </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            clearable
            v-model="item.value"
            autofocus
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="productModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="savingProduct">
        <v-toolbar flat>
          <v-toolbar-title>Select a Product</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="productModal = false"> x </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            rounded
            placeholder="Search"
            prepend-inner-icon="search"
            dense
            v-model="searchProduct"
            clearable
          ></v-text-field>
          <v-list dense style="max-height: 65vh; overflow-y: auto">
            <v-list-item v-if="filterProducts.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No products found</span
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="item in filterProducts"
              :key="item.id"
              @click="saveProduct(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle> {{ item.type }}</v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="item.parentProduct"
                  style="font-size: 12px"
                >
                  Parent: {{ item.parentProduct.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmRemoveProduct"
      width="500px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="confirmRemoveProduct = false">X</v-btn> -->
        </v-toolbar>
        <v-card-text v-if="selectedProduct && selectedProduct.product">
          <p style="font-size: 14px">
            Are you sure you would like to remove
            {{ selectedProduct.product.name }} from traded products?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmRemoveProduct = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="deleteTradedProduct()"
              :loading="deletingTradedProduct"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="tradeVariationModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          Manage Traded Variation
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="secondary" :loading="savingVariation" @click="saveVariation" :disabled="!tradeVariation.packCode">
        <v-icon>save</v-icon>
        </v-btn>
        <v-btn text @click="tradeVariationModal=false, tradeVariation = {}">X</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-text-field outlined dense label="Pack Code" v-model="tradeVariation.packCode" clearable></v-text-field>
        <v-text-field outlined dense label="Count/Size" v-model="tradeVariation.countSize" clearable></v-text-field>
        <!-- <v-text-field outlined dense label="Inventory Code" v-model="tradeVariation.inventoryCode" clearable></v-text-field> -->

      </v-card-text>
    </v-card>
    </v-dialog>

    <v-dialog v-model="productionAliasModal" width="750px" persistent :fullscreen="$vuetify.breakpoint.mobile">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          Production Aliases
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="productionAliasModal= false">X</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-subheader style="font-size: 16px">Select a Producer</v-subheader>
              <v-divider></v-divider>
            <v-text-field
            placeholder="Search"
            autofocus
            prepend-inner-icon="search"
            v-model="searchCustomer"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
          <v-list style="max-height: 50vh; overflow-y: auto" dense>
            <v-list-item
              v-for="organisation in filterOrganisations"
              :key="organisation.id"
              :disabled="organisation.clientStatus == 'ON HOLD' || organisation.clientStatus == 'INACTIVE'"
              @click="setProducer(organisation)"
            >
              <v-list-item-action>
                <v-avatar
                  size="40"
                  v-if="organisation.relatedOrganisation.logo"
                  color="white"
                >
                  <v-img
                    :src="organisation.relatedOrganisation.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar size="40" v-else color="secondary">
                  <h3>{{ organisation.relatedOrganisation.name ?organisation.relatedOrganisation.name.charAt(0) :'' }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organisation.relatedOrganisation.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="organisation.relatedOrganisation.alias && organisation.relatedOrganisation.alias != organisation.relatedOrganisation.name"
                >
                  {{ organisation.relatedOrganisation.alias }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="organisation.clientStatus"
                >
                <v-chip x-small outlined style="border:none">
                  <v-icon small left :color="getClientStatusColor(organisation.clientStatus)">fiber_manual_record</v-icon>
                  {{organisation.clientStatus}}
                </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterOrganisations.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No organisations found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          </v-col>
          <v-col cols="12" sm="6">
            <v-list dense>
              <v-divider></v-divider>

              <v-subheader style="font-size: 16px">Production Aliases
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" @click="updateLinkedRecords()" :loading="updatingLinkedRecords">
                <v-icon>
                  published_with_changes
                </v-icon>
              </v-btn>
                            </template>
                            <span>Update linked records</span>
                        </v-tooltip>
            
              </v-subheader>
              <v-divider></v-divider>
              <v-progress-linear v-if="loadingAliases" indeterminate color="primary" ></v-progress-linear>
              <v-list-item v-if="!productionAliasItem.producer">
                <v-list-item-content class="text-center">
                  <span style="font-size: 12px; color: grey"
                    >Please first select a producer</span>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-action>
                <v-avatar
                  size="40"
                  v-if="productionAliasItem.producer.logo"
                  color="white"
                >
                  <v-img
                    :src="productionAliasItem.producer.logo"
                    contain
                  ></v-img>
                </v-avatar>
                <v-avatar size="40" v-else color="secondary">
                  <h3>{{ productionAliasItem.producer.name ?productionAliasItem.producer.name.charAt(0) :'' }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="productionAliasItem.producer.alias">
                  {{ productionAliasItem.producer.alias }}
                  </span>
                  <span v-else>
                    {{ productionAliasItem.producer.name }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>Producer</v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
              <v-list dense v-if="productionAliasItem.producer" :key="aliasKey">
                <v-chip @click="aliasModal = true">
                  <v-icon small left>add</v-icon> 
                  <span v-if="!aliasModal">Add Alias</span>
                  <v-text-field v-else dense autofocus v-model="alias" hide-details  @keydown.enter="saveAlias"></v-text-field>
                </v-chip>
                <v-chip v-for="alias in productionAliasItem.aliases" :key="alias.id" small class="mx-1">
                {{ alias.code }}
                <v-icon small right @click="removeAlias(alias)">cancel</v-icon>
                </v-chip>
              </v-list>
            </v-list>
          </v-col>

        </v-row>
       
        </v-card-text>
    </v-card>
    </v-dialog>

    <v-menu
      v-model="tradeVariationMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list dense style="background: var(--v-modal-base) !important" v-if="tradeVariation && tradeVariation.id">
        <v-list-item @click="addTradedVariation({packCode: tradeVariation.packCode, countSize: tradeVariation.countSize})">
          <v-list-item-action >
            <v-icon small color="blue">content_copy</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text-left ml-0 pl-0">
            <v-list-item-title>Clone Variation</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="removeTradedVariation(tradeVariation)">
          <v-list-item-action >
            <v-icon small color="red">delete</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text-left ml-0 pl-0">
            <v-list-item-title>Delete Variation</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  data: () => ({
    aliasModal: false,
    alias: null,
    aliasKey: 100,
    confirmRemoveProduct: false,
    data: [],
    deleting: false,
    deletingTradedProduct: false,
    firstProduct: true,
    item: {},
    loading: false,
    loadingAliases: false,
    loadingOrganisations: false,
    loadingProducts: false,
    loadingProductionAlias: false,
    loadingTradedProducts: false,
    loadingTradedVariation: false,
    modal: false,
    productModal: false,
    products: [],
    saving: false,
    savingProduct: false,
    searchProduct: null,
    selectedProduct: {},
    tradedProducts: [],
    types: [
      { key: "Grade", value: "grade" },
      { key: "Brand/Mark", value: "mark" },
      // { key: "Pack Code", value: "packCode" },
      // { key: "Count/Size", value: "countSize" },
      { key: "Inventory Code", value: "inventoryCode" }
    ],
    productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    organisations: {
      data: [],
    },
    productionAliasModal: false,
    productionAliases: [],
    productionAliasItem: {},
    savingVariation: false,
    searchCustomer: null,

    searchTradedVariation: null,
    tradedVariations: [],
    tradeVariation: {},
    tradeVariationModal: false,
    tradeVariationMenu: false,
    updatingLinkedRecords: false,
    x: 0,
    y: 0
  }),
  computed: {
    filterOrganisations(){
      let result = this.organisations.data
      if(this.searchCustomer){
        result = result.filter((item) =>
          item.relatedOrganisation.name.toLowerCase().includes(this.searchCustomer.toLowerCase())
        );
      }
      return result
    },
    filterProducts() {
      let result = this.products.filter((x) => x.type == "Product" && !this.tradedProducts.map(y=>y.productId).includes(x.id));
      if (this.searchProduct) {
        result = result.filter((item) =>
          item.name.toLowerCase().includes(this.searchProduct.toLowerCase())
        );
      }
      return result;
    },
    filterTradedVariations(){
      let result = this.tradedVariations
      if(this.searchTradedVariation){
        result = result.filter((item) =>
          (item.packCode && item.packCode.toLowerCase().includes(this.searchTradedVariation.toLowerCase())) ||
          (item.countSize && item.countSize.toLowerCase().includes(this.searchTradedVariation.toLowerCase())) ||
          (item.inventoryCode && item.inventoryCode.toLowerCase().includes(this.searchTradedVariation.toLowerCase()))
        );
      }
      return result;
    }
  },
  created() {
    this.getTradedProducts();

    this.getProducts();
    this.getProductionAliases()
    this.getRelations()
  },
  methods: {
    addItem(type) {
      this.item = {
        friendlyName: type.key,
        key: type.value,
        productId: this.selectedProduct.productId,
      };
      this.modal = true;
    },
    addTradedVariation(baseItem = {}){
      if(this.selectedProduct && this.selectedProduct.id){
        this.tradeVariation = {
          ...baseItem,
        organisationProductId: this.selectedProduct.id
      }
      this.tradeVariationModal = true
      }
      
    },
    clickTradedVariation(e, item){
      e.preventDefault();
      this.tradeVariationMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.tradeVariation = item;
      this.$nextTick(() => {
        this.tradeVariationMenu = true;
      });
    },
    async deleteOrderSetting(item) {
      this.deleting = true;
      await this.$API.updateOrderSetting({
        id: item.id,
        isActive: false,
        isDeleted: true,
      });
      let index = this.data.findIndex((data) => data.id === item.id);
      this.data.splice(index, 1);
      this.deleting = false;
      this.modal = false;
    },
    async deleteTradedProduct() {
      this.deletingTradedProduct = true;
      await this.$API.updateTradedProduct({
        id: this.selectedProduct.id,
        isActive: false,
        isDeleted: true,
      });
      this.getTradedProducts();
      this.deletingTradedProduct = false;
      this.confirmRemoveProduct = false;
    },
    editItem(item, type) {
      this.item = item;
      this.item.friendlyName = type.key;
      this.modal = true;
    },
    editProductionAlias(item){
      this.productionAliasItem = {
        producer: item,
        producerId: item.id,
        aliases: item.aliases
      }
      console.log(item)
      this.productionAliasModal = true
      this.getProducerProductionAliases(item.id)
    },
    editTradedVariation(item){
      this.tradeVariation = item
      this.tradeVariationModal = true
    },
    async getDataSettings(productId) {
      this.loading = true;
      this.data = await this.$API.getOrderSettings(productId);
      this.loading = false;
    },
    async getProductionAliases(){
      this.loadingProductionAlias = true
      this.productionAliases = await this.$API.getProductionAliases()
      this.loadingProductionAlias = false
    },
    async getRelations() {
      this.loadingOrganisations = true;
      if (
        localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id]
      ) {
        this.organisations = JSON.parse(
          localStorage[
            "relatedOrganisations_" + this.$store.state.currentOrg.id
          ]
        );
      }
      this.organisations = await this.$API.getRelationBasic({
      });
      localStorage.setItem(
        "relatedOrganisations_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.organisations)
      );
      this.loadingOrganisations = false;
    },
    async getProducerProductionAliases(id){
      this.loadingAliases = true
      this.productionAliasItem.aliases = await this.$API.getProductionAliases({producerId: id})
      this.loadingAliases = false
    },
    async getTradedProducts() {
      this.loadingTradedProducts = true;
      this.tradedProducts = await this.$API.getTradedProducts();
      if (this.tradedProducts.length > 0 && !this.selectedProduct.product) {
        this.selectedProduct = this.tradedProducts[0];
        this.getDataSettings(this.selectedProduct.productId);
      }
      this.loadingTradedProducts = false;
    },
    filteredValues(type) {
      return this.data.filter((item) => item.key === type);
    },
    formatBaseUnit(type) {
      let result = this.productQuantityTypes.find((x) => x.value == type);
      return result ? result.text : "";
    },
    async getProducts() {
      this.loadingProducts = true;
      let result = await this.$API.getProducts({});
      this.products = result.data;
      this.loadingProducts = false;
    },
    getClientStatusColor(status) {
      switch (status) {
        case "GOOD STANDING":
          return "green";
        case "INACTIVE":
          return "red";
        case "ON HOLD":
          return "orange";
        case "COD":
          return "blue";
      }
    },
    async getTradedVariations(id){
      this.loadingTradedVariation = true;
      this.tradedVariations = await this.$API.getTradedVariations(id);
      this.loadingTradedVariation = false;
    },
    removeTradedProductPrompt(item) {
      this.selectedProduct = item;
      this.confirmRemoveProduct = true;
    },
    async removeTradedVariation(item){
      await this.$API.updateTradedVariation({
        id: item.id,
        isActive: false,
        isDeleted: true
      })
      this.getTradedVariations(this.selectedProduct.id)
    },
    async saveAlias(){
      let result = await this.$API.createProductionAlias({
        code: this.alias,
        producerId: this.productionAliasItem.producerId
      })
      this.alias = null
      this.productionAliasItem.aliases.push(result)
    },
    async saveOrderSetting() {
      this.saving = true;
      if (this.item.id) {
        let result = await this.$API.updateOrderSetting(this.item);
        let index = this.data.findIndex((item) => item.id === this.item.id);
        this.data.splice(index, 1, result);
      } else {
        let result = await this.$API.createOrderSetting(this.item);
        this.data.push(result);
      }
      this.saving = false;
      this.modal = false;
      this.item = {};
    },
    async saveVariation(){
      this.savingVariation = true
      if(this.tradeVariation.id){
        await this.$API.updateTradedVariation(this.tradeVariation)
      }else{
        await this.$API.createTradedVariation(this.tradeVariation)
      }
      this.getTradedVariations(this.selectedProduct.id)
      this.tradeVariationModal = false
      this.savingVariation = false
    },
    async removeAlias(alias){
      await this.$API.updateProductionAlias({
        id: alias.id,
        isActive: false,
        isDeleted: true
      })
      let index = this.productionAliasItem.aliases.findIndex((item) => item.id == alias.id);
      this.productionAliasItem.aliases.splice(index, 1);
      this.aliasKey ++
    },
    async selectProduct(item) {
      this.selectedProduct = item;
      this.getDataSettings(this.selectedProduct.productId);
      this.getTradedVariations(this.selectedProduct.id)
    },
    async saveProduct(item) {
      this.savingProduct = true;
      let result = await this.$API.createTradedProduct({ productId: item.id });
      this.getTradedProducts();
      this.savingProduct = false;
      this.productModal = false;
    },
    async setProducer(item){
      this.productionAliasItem.producer = item.relatedOrganisation
      this.productionAliasItem.producerId = item.relatedOrganisation.id
this.getProducerProductionAliases(this.productionAliasItem.producerId)
    },
    
    async updateProductSettings(){
      if(this.selectedProduct && this.selectedProduct.id){
        await this.$API.updateTradedProduct(this.selectedProduct)
      }
    },
    async updateLinkedRecords(){
      this.updatingLinkedRecords = true
      await this.$API.updateProductionAliasStock({
        producerId: this.productionAliasItem.producerId,
        aliases: this.productionAliasItem.aliases.map(x=>x.code)
      })
      this.$message.success("Successfully updated linked records")
      this.updatingLinkedRecords = false
    }
  },
};
</script>